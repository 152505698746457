<template>
  <div class="cidian">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>政策词典</el-breadcrumb-item>
    </el-breadcrumb>
    <div >
    <cidianSearch
      @search-data="handleSearch"
      @getHot="getHot"
      ref="serchText"
    />
    <div class="screens">
      <cidianScreen :list="letterList" v-model="letterScreen" @change="handle"/>
      <cidianScreen :list="typeList" v-model="typeScreen" @change="handle2"/>
    </div>
    <div class="content">
      <div v-if="list.length !== 0">
        <div class="docs" v-for="item in list" :key="item.zimu">
          <div>{{ item.zimu }}</div>
          <div>
            <div v-for="item2 in item.children" :key="item2.id" :title="item2.title" @click="goNounExplain(item2.id)">{{ item2.title }}</div>
          </div>
        </div>
      </div>
      <div class="loading" v-else-if="showLoading">
        <img src="../../../assets/pc/images/public/loading2.gif" alt="loading">
        <div style="font-size: 14px;color: #585858;">加载中...</div>
      </div>
      <div class="loading" v-else>暂无数据</div>
    </div>
    </div>
  </div>
</template>

<script>
import cidianSearch from './components/cidianSearch';
import cidianScreen from './components/cidianScreen';
import { request } from '@/network';
import { mapMutations } from 'vuex';
export default {
  components: {
    cidianSearch,
    cidianScreen
  },
  data () {
    return {
      searchScreen: '',
      letterScreen: '',
      typeScreen: '',
      list: [],
      letterList: [
        {
          title: '按字母',
          children: [
            { label: '不限', title: '' },
            { label: 'ABCDE', title: 'ABCDE' },
            { label: 'FGHIJ', title: 'FGHIJ' },
            { label: 'KLMNO', title: 'KLMNO' },
            { label: 'PQRST', title: 'PQRST' },
            { label: 'UVWXYZ', title: 'UVWXYZ' }
          ]
        }
      ],
      typeList: [
        {
          title: '名词类型',
          children: [
            { label: '不限', title: '' },
            { label: '中文', title: 'chinese' },
            { label: '英文', title: 'english' }
          ]
        }
      ],
      showLoading: false
    };
  },
  async mounted () {
    if (this.$route.params.searchData) { this.searchData(this.$route.params.searchData); }
    this.getCidianData();
    await this.getFindDictionarySearch();
  },
  methods: {
    ...mapMutations('cidian', [ 'SET_HOT_LIST' ]),
    async getCidianData () {
      this.showLoading = true;
      const { data: res } = await request({
        method: 'GET',
        url: '/EweiShopQaQuestion/findConditions',
        params: {
          conditionOne: this.searchScreen,
          conditionTwo: this.letterScreen,
          conditionThree: this.typeScreen
        }
      });
      this.showLoading = false;
      this.list = res.data;
    },
    goNounExplain (id) {
      this.$router.push({ path: '/cidian/nounexplain', query: { id } });
    },
    handleSearch (data) {
      this.searchScreen = data;
      this.letterScreen = '';
      this.typeScreen = '';
      this.getCidianData();
    },
    searchData (data) {
      this.searchScreen = data;
      this.getCidianData();
    },
    handle (data) {
      this.$refs.serchText.input = '';
      this.letterScreen = data;
      this.searchScreen = '';
      this.getCidianData();
    },
    handle2 (data) {
      this.$refs.serchText.input = '';
      this.typeScreen = data;
      this.searchScreen = '';
      this.getCidianData();
    },
    getHot (data) {
      if (data.id) {
        this.searchScreen = data.keyword;
        this.$refs.serchText.input = data.keyword;
        this.letterScreen = '';
        this.typeScreen = '';
        this.getCidianData();
      }
    },
    async getFindDictionarySearch () {
      const { data: res } = await request({
        method: 'GET',
        url: '/EweiShopQaQuestion/findDictionarySearch'
      });
      if (res.code === 200) {
        const arr = [ { id: '', keyword: '热门搜索' } ];
        const hotList = [ ...arr, ...res.data ];
        this.SET_HOT_LIST(hotList);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.cidian {
  background-color: #fff;
  padding: 43px 0px;
  .screens {
    padding: 5px 0 42px 0;
    border-bottom: 1px solid #D4D6DA;
  }
  .content {
    min-height: 300px;
    position: relative;
    &>.loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 100px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .docs {
      display: flex;
      border-top: 1px solid #D4D6DA;
      padding: 30px 0 10px 0;
      &>div:nth-child(1) {
        width: 95px;
        line-height: 50px;
        text-align: center;
        font-size: 30px;
        color: #CCCCCC;
      }
      &>div:nth-child(2) {
        width: 995px;
        display: flex;
        flex-wrap: wrap;
        div {
          width: 220px;
          height: 50px;
          padding: 0 10px;
          line-height: 50px;
          text-align: center;
          border: 1px solid #E5E5E5;
          box-sizing: border-box;
          margin: 0 20px 20px 0;
          border-radius: 10px;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
        }
        div:hover {
          border: 1px solid #156ED0;
        }
      }
    }
  }
}
</style>